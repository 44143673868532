import React from 'react'
import { useNavigate } from 'react-router-dom'

import { FormContainerCom, ButtonCom, InputCom, TextCom } from 'components'
import { useHandleAuth, useHandleOther } from 'hook'
import './style.scss'

type SignUpProps = {}

export const SignUp: React.FC<SignUpProps> = props => {
  const navigate = useNavigate()
  const { handleSignUp } = useHandleAuth(props)
  const { translate } = useHandleOther()
  let initialValues: any = {
    name: '',
    phone: '',
    email: '',
    password: '',
    confirm_password: '',
  }

  const validationSchema = (yup: any) =>
    yup.object({
      name: yup.string().required(translate('name-is-required', 'Name is Required')),
      phone: yup.string().required(translate('phone-is-required', 'Phone is Required')),
      email: yup.string().email(translate('email-is-invalid', 'Email is Invalid')),
      password: yup.string().required(translate('password-is-required', 'Password is Required')),
      confirm_password: yup
        .string()
        .required(translate('confirm-password-is-required', 'Confirm password is required'))
        .oneOf([yup.ref('password'), null], translate('password-must-match', 'Password Must Match')),
    })

  const onDataSubmit = (values: any, actions: any) => {
    setTimeout(async () => {
      let post_data = {
        email: values.email,
        name: values.name,
        mobile: values.phone,
        password: values.password,
      }
      handleSignUp(post_data)
    }, 1000)
    actions.setSubmitting(false)
  }

  const handleRouteChange = (route?: any, params?: any) => {
    navigate(route)
  }

  return (
    <div className="sign-up-con">
      <div className="auth_con container">
        <div className="row">
          <div className="col-lg-6 form_wrap">
            <div className="form_card">
              <TextCom size="xxxxl" textAlign="center" weight="xl" className="title_txt">
                {translate('signup', 'Sign Up')}
              </TextCom>
              <FormContainerCom initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values: any, actions: any) => onDataSubmit(values, actions)}>
                {(formikProps: any) => (
                  <>
                    <InputCom label={translate('name', 'Name')} name="name" type="text" required />
                    <InputCom label={translate('phone', 'Phone')} name="phone" type="tel" required />
                    <InputCom label={translate('email', 'Email')} name="email" type="email" />
                    <InputCom label={translate('password', 'Password')} name="password" type="password" required />
                    <InputCom label={translate('confirm-password', 'Confirm Password')} name="confirm_password" type="password" required />
                    <div className="form_foot_sec">
                      <div className="signup_btn">
                        <ButtonCom type="submit" color="light" bgcolor="dark" btnBorderRadius="xxxs" text={formikProps.isSubmitting ? translate('signup...', 'Sign Up...') : translate('signup', 'Sign Up')} />
                      </div>
                      <div className="d-flex justify-content-center gap-3">
                        <TextCom>{translate(`already-have-an-account`, `Already have an account?`)}</TextCom>
                        <TextCom as="a" link color="primary" onClick={() => handleRouteChange('/user/signin')}>
                          {translate('signin', 'SIGN IN')}
                        </TextCom>
                      </div>
                    </div>
                  </>
                )}
              </FormContainerCom>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
